import React from "react";
import Header from "../components/header";

import {Helmet} from "react-helmet";
import "../styles/index.css";
import {StaticImage} from "gatsby-plugin-image";

function Index() {
    return (
        <main>
            <Helmet>
                <title>IxDF Mentor Marketplace</title>
            </Helmet>

            <Header/>

            <section class="bg-ixdf">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 order-2 order-lg-1">
                            <h1 class="text-light">IxDF Mentor Marketplace</h1>

                            <p class="lead text-light">
                                Designed and shipped MVP of a self-service platform that enabled <strong>152 mentoring sessions (95% of all bootcamp students) in the first 2 months</strong>.
                            </p>

                        </div>
                        <div class="col-lg-6 order-1 order-lg-2">

                            <StaticImage
                                src="../images/card-thumb-mentor-marketplace.png"
                                alt="Screen showing Mentor Marketplace"
                                placeholder="blurred"
                                className="img-fluid"
                            />

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- content --> */}

            <div className="message">Case study snapshot. Full version available by request. </div>

            <section>
                <div class="container">
                    <div class="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Situation</h2>
                            <p className="">
                                IxDF Students needed 1:1 UX Mentoring sessions. To achieve ROI, it would have to be
                                fully automated—connecting, scheduling, verifying sessions and paying Mentors with no
                                administration. </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Task</h2>
                            <p className="">
                                Designed and shipped Mentor Marketplace from napkin sketch to MVP in 6 weeks. </p>

                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <h2>Team/Stakeholders</h2>

                            <ul className="">
                                <li>
                                    Nadya, Director of Bootcamps
                                </li>
                                <li>
                                    Yulia, Operations
                                </li>
                                <li>
                                    Jens, Back End Developer
                                </li>
                                <li>
                                    Hasan, Front End Developer
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Actions</h2>

                            <ul className="">
                                <li>
                                    Research—interviews w/ Admins, Mentors
                                </li>
                                <li>
                                    Definition—breadboards, sequence diagrams
                                </li>
                                <li>
                                    Design—high-fidelity screens, user testing, 3x iterations
                                </li>
                                <li>
                                    Documentation—imperative for international team, post MVP iterations
                                </li>
                                <li>
                                    Testing—QA and user testing
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <h2>Result</h2>
                            <p className="">
                                Shipped feature on time. <strong>152 mentoring sessions (95% of all bootcamp students) automated
                                end-to-end in the first 2 months</strong> .
                            </p>

                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- end content --> */}

        </main>
    );
}

export default Index;
